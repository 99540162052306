import type { RouteLocationNormalized } from 'vue-router'
import mixpanel from 'mixpanel-browser'
import { ROUTE_NAMES } from '@/constants'
import {
  redirectIfShortLinkFound,
  redirectIfSigningLinkFound,
  redirectIfInstallmentSigningLinkFound,
  redirectToFileIfPossible,
} from '~/utils'

export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    initMixpanel()

    const redirectPayment = await redirectToPaymentIfPossible(to)
    if (redirectPayment) return navigateTo(redirectPayment, { external: true })

    const redirectSignin = await redirectIfSigningLinkFound(to)
    if (redirectSignin) return navigateTo(redirectSignin, { external: true })

    const redirectInstallmentSignin = await redirectIfInstallmentSigningLinkFound(to)
    if (redirectInstallmentSignin) return navigateTo(redirectInstallmentSignin, { external: true })

    const shortLink = await redirectIfShortLinkFound(to)
    if (shortLink) return navigateTo(shortLink, { external: true })

    const file = redirectToFileIfPossible(to)
    if (file) return navigateTo(file, { external: true })

    const catalogStore = useCatalogStore()
    const modelGroupsStore = useModelGroupsStore()
    const utmStore = useUtmStore()

    utmStore.init(parseUtmTags(to.query))

    // загружаем категории моделей
    if (!modelGroupsStore.list?.length)
      await useAsyncData('fetchModelGroups', () =>
        modelGroupsStore.getList().then(() => true),
      )

    const isCatalogPage = to.name === ROUTE_NAMES.CATALOG_MODEL || to.name === ROUTE_NAMES.CATALOG_MODEL_GROUP
    if (isCatalogPage && !catalogStore.checkFilter(to)) {
      if (import.meta.client) {
        emitYandexMetrika(YANDEX_METRIKA_GOALS.PAGE_NOT_FOUND, {
          from: to.fullPath,
        })
        mixpanel.track('page_not_found', {
          from: to.fullPath,
        })
      }

      throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })
    }
    else {
      if ((to.params.modelGroupSlug || from.params.modelGroupSlug) && to.params.modelGroupSlug !== from.params.modelGroupSlug) {
        const foundModelGroup = modelGroupsStore.list.find(currentModelGroup => currentModelGroup.url === `/${to.params.modelGroupSlug}`)
        if (foundModelGroup) {
          catalogStore.filter.ParentId = foundModelGroup.id
          catalogStore.filter.Models = null
          catalogStore.resetFilters()
        }
      }
    }
  },
)